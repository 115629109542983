export default {
  name: 'icon-button',
  props: [
    'btnText',
    'hasIcon'
  ],
  components: {
  },
  data() {
    return {}
  },
  methods: {}
}