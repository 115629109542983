export default {
  name: 'simple-popup',
  props: [
    'items',
    'hasArrow'
  ],
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {
  },
}