
export default {
  name: 'icon-heading',
  props: [
    'title',
    'iconClass',
    'theme'
  ],
  components: {},
  data() {
    return {}
  },
  methods: {}
}